import { type ExposeStimulus, StimulusControllerBase } from './base_controller'
import { handleCustomClausesEditor } from '../javascripts/editors/customclauses'
import { clone } from '@avvoka/shared'
import { getVisibilityConditionForQuestion } from '../features/VisibilityConditions'
import { getQuestionnaireBody } from '../features/parser/utils'
import { AvvNodeGrammar, Editor, HtmlParser } from '@avvoka/editor'
import QEditorBridge from '../features/questionnaire/utils/QuestionnaireEditorBridge'
import { resolveLoopedQuestions } from '../features/QuestionnaireLoops'
import { deleteFootnotes, deltaToSanitisedHtml } from '../features/editor/utils'
import { useCustomClauseVariantStore } from '@stores/generic/customClauseVariant.store'
import { getActivePinia } from 'pinia'
import { handleEditorStyles } from '../features/editor/styles'
import { useUserStore } from '@stores/generic/user.store'
declare global {
  interface Window {
    clause_variant_id: number
    clause_id: number
    clause_variant_body: string
    can_manage_clause: boolean
    new_version_path: string
  }
}

export interface CustomClauseVariantsController
  extends ExposeStimulus<typeof CustomClauseVariantsController> {}
export class CustomClauseVariantsController extends StimulusControllerBase {
  static targets = [
    'bodyInput',
    'form',
    'questionnaireInput',
    'docxSettingsInput',
    'saveButton'
  ] as const

  async connect() {
    const selector = `#custom_clause_variant-editor${
      window.clause_variant_id ?? ''
    }`

    if (window.clause_variant_id) {
      await this.store.hydrateById(window.clause_variant_id, ['docx_settings'])
    } else {
      this.store.$default()
    }

    await useUserStore(getActivePinia()).hydrate({}, [
      'id',
      'display_name',
      'custom_attributes',
      'custom_operations',
      'is_open_ai_enabled',
      'url_whitelist'
    ])

    await handleCustomClausesEditor(
      this.editorId,
      selector,
      window.clause_variant_body,
      this.store,
      window.can_manage_clause,
      !window.can_manage_clause
    )

    this.unblockSaveButton()
    handleEditorStyles(this.store, this.editor.scroll.node)

    this.questionnaireInputTarget.value = this.questionnaire
    this.handleOldVariants()

    this.prepareUploader('import-docx', false)
    this.prepareUploader('import-automated-docx', true)
  }

  prepareUploader(elementId: string, automated: boolean) {
    const docxButton = document.getElementById(elementId)
    if (docxButton == null) return

    const showImportDialog = () => {
      void window.avv_upload({
        title: localizeText('template.docx_upload.title'),
        description: localizeText('template.docx_upload.desc'),
        path: '/custom_clause_variants/imports/docx',
        accept: ['.docx'],
        data: {
          custom_clause_variant_id: window.clause_variant_id,
          automated
        },
        process: (data: {
          body: string
          options: Backend.Models.TemplateVersion.DocxSettings
          timing: Record<string, number>
        }) => {
          this.store.setDocxSettings(data.options)

          const html = deleteFootnotes(data.body, this.editor)
          const node = HtmlParser.parse(html)
          AvvNodeGrammar.applyDocxGrammar(node)
          this.editor.load(node)

          handleEditorStyles(this.store, this.editor.scroll.node)

          if (data.timing) {
            console.table(data.timing)
          }
        }
      })
    }

    docxButton.addEventListener('click', () => showImportDialog())
  }

  unblockSaveButton() {
    if(!this.hasSaveButtonTarget) return
    const saveButton = this.saveButtonTarget
    saveButton.disabled = false
    saveButton.classList.remove('disabled')
  }

  handleOldVariants() {
    const olderVariantButton = document.querySelector(
      '[data-target="#older_variants"]'
    )
    if (olderVariantButton) {
      const olderVariant = document.getElementById('older_variants')
      if (!olderVariant) return
      olderVariantButton.addEventListener('click', () => {
        olderVariant.classList.toggle('hidden')
      })
    }
  }

  save(event: MouseEvent) {
    event.preventDefault()
    this.bodyInputTarget.value = deltaToSanitisedHtml(this.editor)
    this.questionnaireInputTarget.value = this.questionnaire
    this.docxSettingsInputTarget.value = JSON.stringify(
      this.store.docxSettingsForBackend
    )
    document.forms[0].submit()
  }

  saveNewVersion(event: MouseEvent) {
    event.preventDefault()
    this.formTarget.action = window.new_version_path
    this.save(event)
  }

  buildQuestion(att: string) {
    const template_question = {
      desc: 'Provide your #{att}',
      type: 'input',
      party: '_AvvAuthorParty_aVV',
      opts: {
        required: false,
        default: ''
      },
      uuid: Ast.generateUUID()
    } as Backend.Questionnaire.IQuestion

    const question = clone(template_question)
    question.att = att
    question.desc = question.desc.replace('#{att}', att)
    question.cond = getVisibilityConditionForQuestion(question, this.editor)
    return question
  }

  get questionnaire() {
    const questions = this.attributes.map((att) => this.buildQuestion(att))
    resolveLoopedQuestions(questions, [], this.editor)
    // Add clauseKey to repeater-name and repeater-label to avoid conflicts with other clauses in OB
    questions.forEach((q) => {
      if (!q.opts['repeater-id']) return
      ;['repeater-name', 'repeater-label'].forEach((key) => {
        q.opts[key] = `${q.opts[key] as string} ${this.clauseKey}`
      })
    })
    const questionnaireAvvFormat = getQuestionnaireBody(questions)
    return questionnaireAvvFormat
  }

  get store() {
    return useCustomClauseVariantStore(getActivePinia())
  }

  get attributes(): string[] {
    return new QEditorBridge(this.editor as Editor).attributes()
  }

  get editorId() {
    return `body_${window.clause_variant_id ?? 'new'}`
  }

  get editor() {
    return EditorFactory.get(this.editorId).get()
  }

  get clauseKey() {
    return `${window.clause_id}_${window.clause_variant_id}`
  }
}

export default CustomClauseVariantsController
