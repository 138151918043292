<template>
  <div
    class="w-full relative rounded-xl flex flex-col justify-center bg-white border border-dashed avv-border-gray-60 p-5"
    :class="{
      'h-[200px]': props.height200px
    }"
  >
    <div v-if="props.readyForUpload">
      <input
        id="XLSXFileInput"
        type="file"
        class="absolute inset-0 opacity-0 cursor-pointer"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        @change="upload"
      />
      <p class="pointer-events-none flex flex-col items-center">
        <span class="material-symbols-outlined text-3xl" aria-hidden="true">
          upload
        </span>

        <label for="XLSXFileInput">
          <span
            class="font-bold avv-primary-color"
            v-text="localize('label')"
          />
          {{ localize('text') }}
        </label>
      </p>
    </div>
    <div v-else-if="props.loading">
      <div class="flex loader"></div>
    </div>
  </div>
</template>
<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    height200px?: boolean
    readyForUpload?: boolean
    loading?: boolean
  }>(),
  {
    height200px: false,
    readyForUpload: true,
    loading: false
  }
)

const emit = defineEmits(['upload:e'])

const upload = (e) => {
  emit('upload', e)
}

const localize = (key: string) =>
  window.localizeText(`documents.excel.import.dropzone.${key}`)
</script>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({ name: 'FileUpload' })
</script>
