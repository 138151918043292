import { type ExposeStimulus } from './base_controller'
import CheckAllController from './check_all_controller'

export interface BulkActionsController
  extends ExposeStimulus<typeof BulkActionsController> {}
export class BulkActionsController extends CheckAllController {
  static targets = [
    'container',
    'form',
    'bulkActionInput',
    'recordInput'
  ] as const
  static classes = ['hide'] as const

  updateContainer() {
    if (!this.hasContainerTarget) return false

    const hideClass: string = this.hasHideClass ? this.hideClass : 'hidden'

    const hasFolderIds = this.checked.some((checkbox) =>
      checkbox.id.includes('folder_ids')
    )

    const exportElement = this.element.querySelector('#export')
    const replaceElement = this.element.querySelector('#replace')

    if (hasFolderIds) {
      exportElement?.classList.add(hideClass)
      replaceElement?.classList.add(hideClass)
    } else {
      exportElement?.classList.remove(hideClass)
      replaceElement?.classList.remove(hideClass)
    }

    if (this.checked.length > 0) {
      this.containerTargets.forEach((target) => {
        target.classList.remove(hideClass)
      })
    } else if (this.containerTarget) {
      this.containerTargets.forEach((target) => {
        target.classList.add(hideClass)
      })
    }
  }

  submitForm(action: string) {
    const templateId = sidebarStore.state.templateId || null
    if (templateId) {
      this.formTarget.insertAdjacentHTML(
        'beforeend',
        `<input type="hidden" name="template_ids[]" value="${templateId}">`
      )
    }
    this.bulkActionInputTarget.value = action
    this.formTarget.submit()
  }

  archive(event: Event) {
    event.preventDefault()
    this.submitForm('archive')
  }

  assign(event: Event) {
    const triggerBtn = (event?.target as HTMLElement)?.closest<HTMLElement>(
      '.modal-trigger'
    )
    if (!triggerBtn) return false

    this.formTarget.action = triggerBtn.dataset.formAction || ''

    if (this.recordInputTarget)
      this.recordInputTarget.value = triggerBtn.dataset.recordInputValue || ''

    if (triggerBtn.dataset.method) {
      const methodInput = this.formTarget.querySelector(
        'input[name="_method"]'
      ) as HTMLInputElement
      if (methodInput) {
        methodInput.value = triggerBtn.dataset.method
      }
    }
  }

  delete(event: Event) {
    event.preventDefault()
    const methodInput = this.formTarget.querySelector<HTMLInputElement>(
      'input[name="_method"]'
    )
    if (!methodInput) return false

    methodInput.value = 'delete'
    this.submitForm('delete')
  }

  patch(event: Event) {
    event.preventDefault()
    const methodInput = this.formTarget.querySelector<HTMLInputElement>(
      'input[name="_method"]'
    )
    if (!methodInput) return false

    methodInput.value = 'patch'
    this.submitForm('patch')
  }

  activate(event: Event) {
    event.preventDefault()
    this.submitForm('activate')
  }

  deactivate(event: Event) {
    event.preventDefault()
    this.submitForm('deactivate')
  }

  export(event: Event) {
    event.preventDefault()
    this.submitForm('export')
  }

  destroy(event: Event) {
    event.preventDefault()
    this.submitForm('destroy')
  }
}

export default BulkActionsController
