<template>
  <GlobalDialog
    class="flex flex-col gap-4 w-[450px] dialog avv-gray-100 avv-bg"
  >
    <h2
      class="font-bold"
      v-text="localize(`title.${dialogData.file.errors ? 'error' : 'default'}`)"
    />
    <!-- File -->
    <File
      v-if="dialogData.file.file"
      :file_name="dialogData.file.name"
      :disable="dialogData.documentsGenerated"
      @close="retry"
    />
    <!-- AI comments -->
    <AIComments
      v-if="!dialogData.documentsGenerated"
      :logs="dialogData.ai.logs"
      :loading="dialogData.ai.loading"
      :error="dialogData.ai.error"
    />
    <!-- Errors -->
    <Errors v-if="dialogData.file.errors" :errors="dialogData.file.errors" />
    <!-- File upload-->
    <FileUpload
      v-if="fileUpoad"
      :height200px="dialogData.ai.logs.length < 1"
      :readyForUpload="fileUpoad"
      :loading="dialogData.file.loading"
      @upload="chooseFile"
    />
    <!-- File -->
    <File
      v-else-if="
        dialogData.ai.logs.length > 0 &&
        !dialogData.documentsGenerated &&
        !dialogData.file.errors
      "
      :file_name="dialogData.file.name"
      :disable="dialogData.documentsGenerated"
      @close="retry"
    />
    <!-- File Uploaded success -->
    <DocumentsGenerated
      v-if="dialogData.documentsGenerated"
      :link="dialogData.file.link"
      :large="dialogData.file.size > 50"
    />
    <!-- Buttons -->
    <div class="flex justify-between">
      <div>
        <PreStyleButton mode="primary_full" @click="close">
          {{ localize(dialogData.documentsGenerated ? 'close' : 'cancel') }}
        </PreStyleButton>
      </div>
      <div class="flex gap-x-2.5">
        <PreStyleButton
          v-if="buttonRetryShow"
          mode="primary_full"
          @click="retry"
        >
          {{ localize('retry') }}
        </PreStyleButton>
        <PreStyleButton
          v-if="buttonAskAIShow"
          mode="colorful_border"
          :disable="buttonAskAIDisable"
          @click="askAI"
        >
          <ASVG name="AI" />
          {{
            localize(dialogData.ai.firstTime ? 'ai.review' : 'ai.review_again')
          }}
        </PreStyleButton>
        <PreStyleButton
          v-if="buttonGenerateDocumentsShow"
          mode="primary_full"
          :disable="buttonGenerateDocumentsDisable"
          @click="uploadFile"
        >
          {{ localize(dialogData.ai.firstTime ? 'next' : 'skip_suggestions') }}
        </PreStyleButton>
        <PreStyleButton
          v-if="dialogData.documentsGenerated"
          :type="'a'"
          mode="primary_full"
          href="/documents"
        >
          {{ localize('continue') }}
        </PreStyleButton>
      </div>
    </div>
  </GlobalDialog>
</template>
<script lang="ts" setup>
import { defineComponent, ref, computed } from 'vue'
import { useUserStore } from '@stores/generic/user.store'
import { defineStore, getActivePinia } from 'pinia'
import AiApi from '@api/AiApi'

import GlobalDialog from '../dialogs/GlobalDialog.vue'
import Utils from '../utils'
import AButton from '../_abstract/AButton.vue'
import ASVG from '../_abstract/ASVG.vue'
import PreStyleButton from '../_abstract/PreStyleButton.vue'
import Showdown from '../_abstract/Showdown.vue'
import Errors from './import_dialog/Errors.vue'
import File from './import_dialog/File.vue'
import AIComments from './import_dialog/AIComments.vue'
import FileUpload from './import_dialog/FileUpload.vue'
import DocumentsGenerated from './import_dialog/DocumentsGenerated.vue'

const props = defineProps<{
  templates?: Arr
}>()

const emit = defineEmits(['callback'])

const dialogData = ref({
  file: {
    name: '',
    file: null,
    loading: false,
    link: '',
    size: null,
    errors: null
  },
  ai: {
    logs: [],
    loading: false,
    firstTime: true,
    reviewed: false,
    error: false
  },
  documentsGenerated: false
})

const hasAIAccess = computed(() => useUserStore(getActivePinia()).openAiEnabled)

//File Upoad conditions

const fileUpoad = computed(() => {
  return (
    (!dialogData.value.file.file ||
      (dialogData.value.ai.reviewed && !dialogData.value.ai.firstTime) ||
      dialogData.value.file.loading) &&
    !dialogData.value.documentsGenerated &&
    !dialogData.value.file.errors
  )
})
const readyForUpload = computed(() => {
  return (
    !dialogData.value.file.file ||
    (dialogData.value.ai.reviewed && !dialogData.value.ai.firstTime)
  )
})

//Buttons conditions

const buttonRetryShow = computed(() => {
  return dialogData.value.file.file && dialogData.value.file.errors
})
const buttonAskAIShow = computed(() => {
  return (
    !dialogData.value.documentsGenerated &&
    !dialogData.value.file.errors &&
    hasAIAccess.value
  )
})
const buttonAskAIDisable = computed(() => {
  return (
    !dialogData.value.file.file ||
    dialogData.value.file.errors ||
    dialogData.value.ai.reviewed ||
    dialogData.value.ai.loading
  )
})
const buttonGenerateDocumentsShow = computed(() => {
  return !dialogData.value.documentsGenerated && !dialogData.value.file.errors
})
const buttonGenerateDocumentsDisable = computed(() => {
  return !dialogData.value.file.file || dialogData.value.file.errors
})

//Actions

const close = () => {
  emit('callback')
}

const retry = () => {
  if (!dialogData.value.documentsGenerated) {
    dialogData.value.file.name = ''
    dialogData.value.file.file = null
    dialogData.value.file.errors = null
    dialogData.value.file.link = ''
    dialogData.value.file.size = null
  }
}

const chooseFile = (e: event) => {
  dialogData.value.ai.reviewed = false
  dialogData.value.file.name = ''
  dialogData.value.file.file = null

  const input = e.target as HTMLInputElement
  dialogData.value.file.file = input.files![0] as File

  if (
    dialogData.value.file.file.type !==
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  ) {
    dialogData.value.file.errors = { file_generic: true }
    return console.warn('File type mismatch')
  }
  if (dialogData.value.file.file.size > 256e6) {
    dialogData.value.file.errors = { file_large: true }
    return console.warn('File too large')
  }
  dialogData.value.file.name = dialogData.value.file.file.name
}

const uploadFile = () => {
  const formData = new FormData()
  formData.append('file', dialogData.value.file.file)
  formData.append('template_ids', props.templates.join(','))

  Utils.axios
    .post('/documents/imports/xlsx', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then((response) => {
      dialogData.value.file.errors = response.data?.errors
      dialogData.value.file.size = response.data?.size
      dialogData.value.file.link = response.data?.link
      if (!dialogData.value.file.errors)
        dialogData.value.documentsGenerated = true
    })
}

const askAI = () => {
  if (dialogData.value.ai.error) dialogData.value.ai.error = false
  dialogData.value.ai.loading = true
  if (dialogData.value.ai.firstTime) dialogData.value.ai.firstTime = false

  const formData = new FormData()
  formData.append('file', dialogData.value.file.file)
  formData.append('template_ids', props.templates.join(','))

  Utils.axios
    .post('../ai/review_xlsx', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then((response) => {
      dialogData.value.ai.loading = false
      dialogData.value.ai.reviewed = true
      dialogData.value.ai.logs.push({
        fileName: dialogData.value.file.name,
        body: response.data.message
      })
    })
    .catch((e) => {
      dialogData.value.ai.loading = false
      dialogData.value.ai.error = true
    })
}

const localize = (key: string) =>
  window.localizeText(`documents.excel.import.${key}`)
</script>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({ name: 'ImportDialog' })
</script>
