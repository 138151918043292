<template>
  <div class="flex flex-col justify-center">
    <div
      class="max-h-[35vh] bg-white rounded-lg overflow-y-auto logs styled_list"
    >
      <div v-for="log in props.logs" class="pb-2.5">
        <h3 class="text-base font-bold py-1">
          <ASVG class="inline align-baseline" name="AvvokaLogoBlue" />
          {{ localize('answer_title') }} {{ log.fileName }}:
        </h3>
        <Showdown
          :data="log.body"
          :settings="{
            openLinksInNewWindow: true,
            disableForced4SpacesIndentedSublists: true
          }"
        />
      </div>
      <div v-if="props.loading" class="flex justify-center">
        <ASVG class="h-12 w-12" name="Loading" />
      </div>
      <div
        v-if="props.error"
        class="flex flex-col justify-center items-center avv-danger-color p-2.5"
      >
        <p>
          <span class="material-symbols-outlined text-5xl"> error </span>
        </p>
        <p v-text="localize('error')" />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import Showdown from '../../_abstract/Showdown.vue'
import ASVG from '../../_abstract/ASVG.vue'

const props = withDefaults(
  defineProps<{
    logs?: Object[]
    loading?: boolean
    error?: boolean
  }>(),
  {
    loading: false,
    error: false
  }
)

const localize = (key: string) =>
  window.localizeText(`documents.excel.import.ai.${key}`)
</script>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({ name: 'AIComments' })
</script>
