import ImportDialog from './../features/excel_templates/ImportDialog.vue'
import ExportDialog from './../features/excel_templates/ExportDialog.vue'
import { type ExposeStimulus, StimulusControllerBase } from './base_controller'
import { useUserStore } from '@stores/generic/user.store'
import { getActivePinia } from 'pinia'
import axios from 'axios'

export interface ExcelTemplatesController
  extends ExposeStimulus<typeof ExcelTemplatesController> {}
export class ExcelTemplatesController extends StimulusControllerBase {
  static values = {
    template: String
  }

  userStore = useUserStore(getActivePinia())

  async import() {
    await this.userStore.hydrate({}, ['is_open_ai_enabled'])
    const templates = this._selectedTemplates().concat(
      await this._selectedFolders()
    )
    window.avv_dialog({
      vueDialog: ImportDialog,
      callback: () => {},
      props: {
        templates: templates
      }
    })
  }

  async export() {
    const selectedItems = this._selectedTemplates().concat(
      await this._selectedFolders()
    )
    window.avv_dialog({
      vueDialog: ExportDialog,
      callback: () => {},
      props: {
        templates: selectedItems
      }
    })
  }

  _selectedTemplates(): string[] {
    if (this.templateValue) {
      return [this.templateValue]
    } else {
      const form = document.getElementById('templates-form')!
      const checkboxes = form.querySelectorAll<HTMLInputElement>(
        '.templates-item-check-input'
      )
      return Array.from(checkboxes)
        .filter((checkbox) => checkbox.checked)
        .map((checkbox) => checkbox.value)
    }
  }

  async _selectedFolders(): Promise<string[]> {
    const checkboxes = Array.from(
      document.querySelectorAll<HTMLInputElement>('.folders-item-check-input')
    )
    const folder_ids = checkboxes
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.value)
    const templates = await this.templates_in_folders(folder_ids)
    return templates
  }

  async templates_in_folders(folder_ids: string[]): Promise<string[]> {
    const url = `/templates/folder_template_ids`
    const requestData = { folder_ids: folder_ids }
    const response = await axios.post<{ template_ids: string[] }>(
      url,
      requestData
    )
    if (response.status === 200) {
      return response.data.template_ids.map((id) => String(id))
    }
    return []
  }
}

export default ExcelTemplatesController
